@import '../styles/default-colors.scss';

div.mv-app-shell {
    position: relative;    
    
    min-height: 100vh;    
    height: 100%;

    min-width: 100vw;
    width: 100%;

    margin: 0;    
    padding: 0;
    
    background-color: rgba($rgb-bg-color, $rgb-bg-opacity);
}
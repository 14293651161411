@import '../styles/default-colors.scss';

div.mv-navigation-bar {
    position: relative;
    height: 48px;
    background-color: $rgb-navbar;

    div.mv-app-chooser { 
        position: relative;
        float: left;
        width: 47px;
        height: 50px;

        text-align: center; 

        i {
            margin-top: 11px;
            font-size: 24px;
        }
    }

    div.mv-header {
        display: flex;
        position: relative;        
        float: left;                   

        img {
            margin-top: 13px;
            height: 24px;
            margin-right: 10px;
        }

        span { 
            display: block;
            height: 40px;      
            padding-top: 10px;
            font-size: 24px;
            font-weight: 700;
        }
    }

    div.mv-content {
        display: block;
        padding: 0;
        margin: 0;
        margin-left: 10px;
        height: 100%;   
        width: calc(100% - 10px);     
    }
}

div.mv-nav-bar-persona {
    padding-top: 16px;
    cursor: pointer;    
}
div.mv-selectorlistview-item {
    
    display: flex;   
    margin-top: 20px;
    cursor: pointer;
    
    img {
        flex: 0 0 155px;
        transition: box-shadow 0.25s ease 0s;
        height: 100px;
        box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
        pointer-events: none;
    }

    & > div {
        margin-left: 20px;
    }
}
div.mv-app-personal-callout {
    width: 320;
    max-width: '90%';
    

    a:focus-visible {
        outline: none;
    }

    div.ms-Stack.headline {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    a.logout-link {
        text-decoration: none;

        &.logout-link-end {
            padding-right: 10px;
        }
    }

    .mv-app-personal-callout-persona {
        padding: 20px 24px;
    }

    .mv-app-personal-callout-actions {
        background-color: rgba(0,0,0,.08);

        .ms-StackItem {
            padding: 10px 20px;

            &:hover {
                background-color: rgba(0,0,0,.08);
                cursor: pointer;
            }
        }
    }
}
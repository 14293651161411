div.dashboard-grid-widget {

    overflow: hidden;
    background-color: white;
    cursor: move;

    &.react-grid-item  {
        padding: 5px 15px 15px 15px;
        box-sizing: border-box;
        border: 1px solid #e1dfdd;
        border-radius: 2px;
        box-shadow:  0 1.6px 3.6px 0 rgba(0,0,0,.132),0 .3px .9px 0 rgba(0,0,0,.108);
    }

    &.react-grid-no-edit {

        cursor: default;

        .react-resizable-handle {
            display: none;
        }

        div.dashboard-grid-widget-delete {
            display: none;
        }
    }

    &.react-grid-item-with-header {
        padding-top: 3px;
    }

    div.dashboard-grid-widget-header {    
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        position: relative;
        width: 100%;   
        
        & > h3 {     
            line-height: 20px;           
            margin-top:12px;             
            margin-bottom:12px;               
        }

        & > div.dashboard-grid-widget-header-commands {
            display: block;
            flex: 1;


            .ms-FocusZone {
                padding-right: 0;
            }
        }

        & > div.dashboard-grid-widget-header-operations {
            display: flex;
            margin-left: 20px;

            div.ms-Dropdown-container {
                margin: 0;
                margin-top: 9px;

                .ms-Dropdown-title {
                    border-style: none;
                }
            }
        }
    }

    div.dashboard-grid-widget-content {    
        position: relative;           

        & > div.dashboard-grid-widget-content-wrapper {
            height: 100%;            
        }
    }

    div.dashboard-grid-widget-footer {
        position: absolute;
        bottom: 5px;
        right: 10px;
        color: #ccc;
        font-size: 10px;
    }
}
div.mv-metadatalist {

    width: 90%;
    margin-top: 20px;

    div.mv-metadatalist-items-container {

        display: flex;
        flex-wrap: wrap;
        margin: -12.5px 16px -12.5px -16px;

        div.mv-metadataitem {

            flex: 1 1 calc(50% - 32px);
            max-width: 100%;
            margin: 12px 16px;

            div.header {
                font-weight: 600;
                margin-bottom: 9px;
            }

            div.value {
                word-break: break-all;
                margin-bottom: 9px;
            }
        }
    }
}
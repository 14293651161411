@import 'normalize.css';

@import '@mevodo/mv-ds-fonts/fonts.scss';

@import './styles/default-colors.scss';
@import './styles/centered.scss';
@import './styles/shadow.scss';

body { 
    
    // reset paddings and margins
    margin: 0;
    padding: 0;

    // set the standard font
    font-family: MevodoRegular, sans-serif;

    // standard font-size
    font-size: 14px;
    font-weight: 600;

    // background 
    background-color: rgba($rgb-bg-color, $rgb-bg-opacity);

    h2 {
        font-size: 28px;
        font-weight: 700;
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
    }    
}

div.ms-CommandBar.root-97 {
    padding-left: 0px !important;
}

div.ms-DetailsHeader.root-116 {
    padding-top: 0px !important;
}

div.mv-command-bar-wrapper {
    width: 100%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #edebe9;    
}

div.mv-content-container {
    width: 100%;
    background-color: $rgb-white;

    &.standalone {
        border: 1px;
        margin-top: 2px;
        height: 100%;
        

        .mv-command-bar-wrapper {
            margin-left: 1px;
        }
    }
}

div.mv-breadcrumb-wrapper {
    margin-left: 48px;    
}


div.fieldRendererWithCommands {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    div.nameField {        
        display: block;
        overflow: hidden;
    }

    div.nameFieldCommandBar {        
        display: block;
    
        button {
            background-color: transparent;
        }
    }
}

div.mv-terminal {    
    display: block;
    position: static;
    height: auto;
    padding: 1.25rem 1.813rem;
    border-radius: 0.25rem;
    box-sizing: border-box;
    overflow: auto;
    background-color: #001c38;    
    line-height: 1.5rem;
    font-size: .875rem;
    color: #20ffc7;    

    pre {
        display: block;
        white-space: pre-wrap;
        margin: 0;
        font-family: Roboto Mono,monospace;
        font-size: 1em;        
    }
}

.icon {
    font-weight: 600;

    &.finished {
        color: green;        
    }

    &.failed {
        color: red;        
    }

    &.evicted {
        color: red;        
    }
}

i {
    &.statusvalid, &.statusActive {
        color: green;        
    }

    &.statusinterrupted, &.statusInActive {
        color: red;        
    }

    &.statuspending {
        color: green;        
    }
}
div.chat-control {
    display: flex;
    flex-direction: column;        
        
    div.chat-box {
        flex: 1;
        display: flex;                    
        flex-direction: column;
        overflow-y: scroll;
        
        // The user chat message should be right oriented, not bigger than 70% of the width
        // but fit to the text size.
        div.chat-message-user {
            display: block;
            background-color: lightblue;
            border-radius: 6px;
            margin: 5px;
            padding: 8px;
            max-width: 70%;
            word-wrap: break-word;
            align-self: flex-end;
        }        

        div.chat-message-assistant {            
                        
            margin-top: 10px;

            div.assistant-header {
                display: block;
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 16px;
            }

            div.assistant-body {
                display: block;
                margin-left: 26px;                                            
                max-width: 70%;
                word-wrap: break-word;                
            }

            div.assistant-actions {
                display: block;
                margin-left: 26px;   
                margin-top: 10px;                                                                                     
            }
        }                
    }
    
    div.prompt-box {                
        display: block;        
        background-color: white;
        border: 1px solid lightgrey;
        border-radius: 6px;             
        padding: 8px;
        
        p.prompt-input {            
            display: block;                                        
            border: none;
            margin: 0;
            word-wrap: break-word;
            user-select: text;
            
            &:focus-visible {
                outline: none;
            }
        }        

        div.prompt-actions {
           display: flex;
           justify-content: space-between;
           flex-direction: row;
           margin-top: 20px;
           margin-bottom: 5px;

           p {         
                padding-top: 18px;                       
                font-size: 10px;                
                margin: 0;
           }

           button { 
                max-width: 32px;
                max-height: 32px;
                color: blue;
                border: none;
           }
        }
    }
}
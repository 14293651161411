
// colors 
$rgb-light: rgb(248, 249, 250);
$rgb-white: rgb(255, 255, 255);
$rgb-antracit: rgb(72, 70, 68);

$rgb-gray: rgb(243, 242, 241);
$rgb-gray-accent: rgb(237, 235, 233);
$rgb-gray-light: rgb(237, 235, 233);

$rgb-mevodo-yellow: rgb(255,237,0);

// semantic color variables 
$rgb-bg-color: $rgb-light;
$rgb-bg-opacity: 1;

$rgb-surface-color: $rgb-white;
$rgb-surface-opacity: 1;
$rgb-surface: rgba($rgb-surface-color, $rgb-surface-opacity);

$rgb-navbar-color: $rgb-mevodo-yellow;
$rgb-navbar-opacity: 0.8;
$rgb-navbar: rgba($rgb-navbar-color, $rgb-navbar-opacity);

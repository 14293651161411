div.mv-navigational-detaillist {
        
    div.noSelection.notEmbedded {
        
        div[role=row] {                    
            div.ms-DetailsHeader-cell[data-item-key=column0], div.ms-DetailsRow-cell[data-automation-key=column0] {
                margin-left: 24px;
            }
        }

        div.ms-Shimmer-container {
            padding-left: 22px;
        }
    }
}

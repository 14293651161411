img.center {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

div.center {
    background-color: transparent;
    position: absolute; /*Can also be `fixed`*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}
@import '../styles/default-colors.scss';

div.mv-app-bar {
    position: absolute;
    left: 0px;
    top: 48px;
    bottom: 0px;
    width: 47px;
    height: calc(100vh - 47px);
    background-color: $rgb-gray;

    border-right-color:$rgb-gray-accent;
    border-right-style: solid;
    border-right-width: 1px;


    div.mv-app-bar-item {   
        position: relative;     
        
        button {            
            width: 47px;
            height: 47px;  
            
            &:hover {
                background-color: white;
                border-top-color: #c7c7c7;
                border-bottom-color: #c7c7c7;
            }
        }        
    }

    div.mv-app-bar-item.mv-app-bar-item-selected {

        button {
            background-color: white;
            border-top-color: #c7c7c7;
            border-bottom-color: #c7c7c7;

            &::before {
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: rgb(36, 36, 36);                
                height: 12px;
                width: 3px;
                transition: width 0.3s, height 0.3s;
                content: "";                
            }

            &:hover {

                &::before {
                    height: 3px;
                    width: 3px;
                }
            }
        }        
    }
}
@import './../styles/default-colors.scss';

div.mevodo-fluent-stage {
    position: absolute;
    width: 50%;
    height: 50%;
    background-color: $rgb-surface !important;
    padding: 22px;    

    div.mevodo-fluent-stage-brand {
        font-size: 16px;
        font-weight: 400;        

        img {
            display: inline-block !important;
            vertical-align: text-top !important;
        }    
    }
}